<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="编辑子分类"
           :afterClose="remove"
           width="700px"
           @cancel="remove"
           @ok="handleOk">
    <div>
      <ul class="type-table-list">
        <li class="type-table-list__item">
          <div class="flex type-table-list__item-span font-bold">
            <div class="flex-1 mr-3">分类图片</div>
            <div class="flex-1 mr-3">中文名称</div>
            <div class="flex-1 mr-6">英文名称</div>
            <div style="width:50px;">操作</div>
          </div>
        </li>
        <div id="type-table-list">
          <li class="type-table-list__item"
              v-for="(item,i) in newTypeList"
              :key="item.type_id">
            <div class="flex type-table-list__item-span">
              <div class="flex-1 mr-3">
                <upload-image type="12" :value.sync="item.image_path" />
              </div>
              <span class="flex-1 mr-3">
                <a-input v-model="item.type_name" />
              </span>
              <span class="flex-1 mr-6">
                <a-input v-model="item.type_name_en" />
              </span>
              <span style="width:50px;">
                <a-button class="p-0" type="link" @click="onTypeDelete(i)">删除</a-button>
              </span>
            </div>
          </li>
        </div>
      </ul>
      <div v-if="newTypeList.length < 5">
        <span style="width:60px;"> </span>
        <a-button type="link"
                  icon="plus"
                  @click="onTypeAdd">新增</a-button>
      </div>
    </div>

  </a-modal>

</template>

<script>
import _ from 'lodash'
import Sortable from 'sortablejs'
import UploadImage from "@/components/upload-image"

import { editSubType } from '@/api/pc/mall-sell.js'

export default {
  components: { UploadImage },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    pId: {
      type: String,
    },
    typeList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      newTypeList: [],
      // newTypeList: [{
      //   type_id: 0,
      //   type_name: "",
      //   type_name_en: "",
      //   image_path: ""
      // },{
      //   type_id: 0,
      //   type_name: "",
      //   type_name_en: "",
      //   image_path: ""
      // },{
      //   type_id: 0,
      //   type_name: "",
      //   type_name_en: "",
      //   image_path: ""
      // },{
      //   type_id: 0,
      //   type_name: "",
      //   type_name_en: "",
      //   image_path: ""
      // },{
      //   type_id: 0,
      //   type_name: "",
      //   type_name_en: "",
      //   image_path: ""
      // }]
    }
  },
  async mounted () {
    if(this.typeList.length){
      this.newTypeList = _.cloneDeep(this.typeList)
    }
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      if(this.newTypeList.some(el=>{
        return !el.type_name || !el.type_name_en || !el.image_path
      })){
        this.$message.info("请完善分类数据");
        return
      }

      const _this = this
      this.$confirm({
        title: '提示',
        content: _this.typeList.length == 0 ? '添加子分类后，原分类下的商品将被删除，是否确认操作？' : '是否确认更新当前分类内容?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          editSubType({ parent_type_id: _this.pId, son_type_data: JSON.stringify(_this.newTypeList) }).then(res => {
            if (res.code === 0) {
              _this.$message.success('编辑成功！')
              _this.$emit('update:show', false)
              _this.$emit('ok', _this.newTypeList)
            }
          })

        },
        onCancel () {
          console.log('Cancel');
        },
      });

    },
    onTypeDelete (e) {
      this.newTypeList.splice(e, 1)
    },
    onTypeAdd () {
      this.newTypeList.push({
        type_id: 0,
        type_name: '',
      })
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('type-table-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.newTypeList.splice(event.oldIndex, 1)
          _this.newTypeList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },
  }
}
</script>

<style>
.type-table-list {
  list-style: none;
}
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.subtype-tab {
  background: #653410;
  color: #fff;
  border-radius: 30px;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 12px;
}
</style>