<template>
  <a-modal
    :visible.sync="show"
    title="添加商品"
    destroyOnClose
    width="45%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <!-- 蛋糕列表 -->
        <a-row v-if="isCake">
          <a-col :span="20">
            <a-form-model-item prop="goods_name" label="商品名" :label-col="{ span: 4 }">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 其他产品列表 -->
        <a-row v-else>
          <a-col :span="8">
            <a-form-model-item prop="type" label="分类">
              <a-select v-model="searchForm.type" placeholder="产品属性" style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="goods_name" label="商品名">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      rowKey="goods_id"
      :row-selection="rowSelection"
      :customRow="Rowclick"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :customHeight="300"
    >
    <div slot="footer">
        新增
        <span class="text-red-500">{{ rowSelection.selectedRowKeys.length }}</span>项
      </div>
    </base-table>
  </a-modal>
</template>

<script>
import { goodsType, formatGoodsType } from '@/utils/type.js'
import { getGoodsList } from "@/api/goods" // 如果没有传入的请求列表数据的函数，则默认查询商品库

import _ from "lodash"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
    newKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
    isCake: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goodsType: goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        // goods_number: "",
        goods_name: "",
        type: "",
        status: 1,
        page: 1,
        page_count: 30,
      },
      columns: [
        // {
        //   title: "商品编码",
        //   dataIndex: "goods_number",
        //   align: "center",
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "产品属性",
          dataIndex: "type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
      ],
      tableData: [],
      total: 0,
      selectRows: [],
      unSlectRowKeys: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id)
            this.selectRows.unshift(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.goods_id
            )
            this.unSlectRowKeys.push(record.goods_id)
            this.selectRows = this.selectRows.filter(el => el.goods_id != record.goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            )
            this.selectRows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },

        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.selectKeys.includes(record.goods_id),
            },
          }
        },
      },
    }
  },
  mounted() {
    this.initData()
    console.log(this.selectKeys)
    this.rowSelection.selectedRowKeys = _.cloneDeep(this.selectKeys)
  },
  methods: {
    async initData() {
      const { data } = await getGoodsList(this.searchForm, this.isCake)
      this.tableData = data.list.filter((item) => {
        return !this.selectKeys.includes(item.goods_id)
      })

      this.total = data.total_count
    },
    // 保存
    handleOk() {
      if(this.selectRows.some(el=>el.type==4)){
        this.$message.info("不能选择茶饮")
        return
      }
      this.$emit(
        "ok",
        this.selectRows
      )
      this.$emit("update:show", false)
    },
    handlerSearch() {
      this.page = 1
      this.initData()
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
    },

    Rowclick(record) {
      return {
        on: {
          click: () => {
            //如果是已选就去掉
            if (this.rowSelection.selectedRowKeys.includes(record.goods_id)) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              )
              this.selectRows = this.rows.filter(
                (el) => el.goods_id != record.goods_id
              )
            } else {
              this.rowSelection.selectedRowKeys.unshift(record.goods_id)
              this.selectRows.push(record)
            }
          },
        },
      }
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>