<template>
  <div>
    <base-table ref="mallListTable"
                id="mallListTable"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :addHeight="-35">
      <template #toolbar>
        <div class="flex justify-between" style="align-items:end;">
          <div>
            <h4 class="text-lg font-bold">网站在售商品</h4>
            <div class="flex justify-between">
              <a-tabs type="card"
                      @change="handlerTypeChange"
                      :activeKey="activeKey">
                <a-tab-pane key="-1" tab="全部商品"></a-tab-pane>
                <a-tab-pane v-for="item in typeList"
                            :key="item.type_id"
                            :tab="item.type_name"></a-tab-pane>
                <div slot="tabBarExtraContent">
                  <a-button class="p-0 ml-4" type="link"
                            @click="handlerEditType">编辑分类</a-button>
                </div>
              </a-tabs>
            </div>
            <div v-if="activeKey != -1" class="flex justify-between">
              <a-tabs type="card"
                      @change="handlerTypeSubChange"
                      v-model="activeSubKey">
                <a-tab-pane v-for="item in typeSubList"
                            :key="item.type_id"
                            :tab="item.type_name"></a-tab-pane>
                <div slot="tabBarExtraContent">
                  <a-button class="p-0 ml-4" type="link"
                            @click="isShowSubType = true">编辑子分类</a-button>
                </div>
              </a-tabs>
            </div>
          </div>
          <div class="mb-1">
            <a-button v-if="!isEdit" 
                      @click="handlerEdit">编辑/排序</a-button>
            <template v-if="isEdit">
              <a-button type="primary"
                        v-if="activeKey != -1"
                        @click="handlerAddGoods">添加商品</a-button>
              <a-button class="ml-3" type="danger"
                        @click="handlerEditGoodsSave">保存</a-button>
              <a-button class="ml-3" type="dashed"
                        @click="handlerEditGoodsCancel">取消</a-button>
            </template>
          </div>
        </div>
      </template>

      <template #cover_url="{text}">
        <img :src="text+cutQuery(50,50)" style="height:50px;" />
      </template>
      <template #operation="{record,index}">
        <a-button class="p-0" type="link"
                  @click="handlerDetail(record.goods_id)">详情</a-button>
        <a-button class="p-0 ml-4" type="link" 
                  v-if="activeKey!=-1 && isEdit" @click="handlerDelete(index)">删除</a-button>
      </template>
    </base-table>

    <type-edit-modal v-if="isShowType" :show.sync="isShowType"
                     :typeList="typeList"
                     @ok="initData" />
    <sub-type-edit-modal v-if="isShowSubType" :show.sync="isShowSubType"
                         :typeList="typeSubList"
                         :pId="activeKey.toString()"
                         @ok="handlerEditSubTypeOk" />
    <add-goods-modal v-if="isShowAddGoods" :show.sync="isShowAddGoods"
                     :typeId="activeSubKey"
                     :selectKeys="selectKeys"
                     :selectRows="tableData"
                     @ok="handlerAddGoodsOk" />
  </div>
</template>

<script>
import Sortable from "sortablejs"

import TypeEditModal from "./components/type-edit-modal.vue"
import SubTypeEditModal from "./components/sub-type-edit-modal.vue"

import AddGoodsModal from "./components/add-goods-modal.vue"

import pageData from "./columns"
import {filterList} from '@/utils/index'
import { cutQuery } from '@/utils/ossImg'

import {
  getTypeList,
  getSubTypeList,
  getPcMallAllGoods,
  getGoodsListByTypeId,
  sortPcMallAllGoods,
  editTypeGoods,
  getSkuListByGoodsId
} from "@/api/pc/mall-sell.js"

export default {
  components: {
    TypeEditModal,
    SubTypeEditModal,
    AddGoodsModal,
  },
  data () {
    return {
      ...pageData,
      isShowType: false,
      isShowSubType: false,
      isEdit: false,
      isShowAddGoods: false,
      activeKey: null,
      activeSubKey: null,
      total: 0,
      typeList: [],
      typeSubList: [],
      tableData: [],
      selectKeys: [],

      sort_table: null,

      loading: false
    }
  },
  async mounted () {
    await this.initData()
  },
  methods: {
    cutQuery,
    async initData () {
      const { data, code } = await getTypeList()
      if (code == 0) {
        this.typeList = data.list
        // 默认选中
        if (this.$route.query.typeId) {
          const typeArr = this.$route.query.typeId.split(',')
          this.activeKey = parseInt(typeArr[0])
          if (typeArr.length > 1) {
            this.activeSubKey = parseInt(typeArr[1])
            await this.getSubTypeList(typeArr[0])
          } else {
            this.activeSubKey = parseInt(typeArr[0])
            await this.initGoodsData()
          }
          this.isEdit = true
          this.getGoodsList(this.$route.query.goodsId)

          this.$nextTick(() => {
            this.rowDrop()
          })
        } else {
          this.handlerTypeChange("-1")
        }
      }
    },
    // 获取子分类列表
    async getSubTypeList (id) {
      const { data, code } = await getSubTypeList({ parent_type_id: id })
      if (code == 0) {
        if (data.list.length > 0) {
          this.typeSubList = data.list.map(item => {
            item.type_color = item.type_color || "#653410"
            return item
          })
          if (!this.$route.query.typeId) {
            this.activeSubKey = data.list[0].type_id
          }
        } else {
          this.typeSubList = []
          this.activeSubKey = this.activeKey
        }
        this.initGoodsData()
      }
    },
    async getGoodsList (ids) {
      getSkuListByGoodsId({ goods_ids: ids }).then(res => {
        this.tableData = filterList(res.data.list.concat(this.tableData), 'goods_id')
        this.selectKeys = this.tableData.map((el) => {
          return el.goods_id
        })
      })
    },
    async initGoodsData () {
      if(this.activeSubKey == -1){
        const res = await getPcMallAllGoods()
        this.tableData = res.data.list
        this.selectKeys = res.data.list.map((el) => {
          return el.goods_id
        })
      }else{
        const res = await getGoodsListByTypeId({ type_id: this.activeSubKey })
        this.tableData = res.data.list
        this.selectKeys = res.data.list.map((el) => {
          return el.goods_id
        })
      } 
    },
    handlerTypeChange (id) {
      if(id){
        this.activeKey = id
      }
      if(this.activeKey == -1){
        this.typeSubList = []
        this.activeSubKey = -1
        this.initGoodsData()
      }else{
        this.getSubTypeList(this.activeKey)
      }
    },
    handlerTypeSubChange (sid) {
      if(sid){
        this.activeSubKey = sid
      }
      this.initGoodsData()
    },
    handlerEditSubTypeOk () {
      setTimeout(() => {
        this.getSubTypeList(this.activeKey)
      }, 100)
    },
    handlerEdit(){
      this.stopRowDrop()
      this.isEdit = true
      this.$nextTick(() => {
        this.rowDrop()
      })
    },
    handlerAddGoods () {
      this.isShowAddGoods = true
    },

    handlerAddGoodsOk (rows) {
      let ids = rows.map((el) => el.goods_id)
      if (rows.length > 0) {
        getSkuListByGoodsId({ goods_ids: ids.join(",") }).then(res => {
          if(res.code === 0 && res.data.list.length>0){
            this.tableData = res.data.list.concat(this.tableData)
          }
        })
      }
    },
    async handlerEditGoodsSave () {
      if(this.loading) return
      const goods_data = this.tableData.map((item) => {
        return {
          goods_id: item.goods_id,
        }
      })

      let res
      this.loading = true
      if(this.activeKey == -1){
        res = await sortPcMallAllGoods({ 
          goods_data: JSON.stringify(goods_data)
        })
      }else{
        res = await editTypeGoods({ 
          type_id: this.activeSubKey,
          goods_data: JSON.stringify(goods_data)
        })
      }
      this.loading = false

      if (res.code === 0) {
        this.$message.success("编辑成功")
        this.isEdit = false
        this.stopRowDrop()
        this.initGoodsData()
      }
    },
    handlerEditGoodsCancel () {
      this.isEdit = false
      this.stopRowDrop()
      this.handlerTypeSubChange()
    },
    handlerDelete (index) {
      this.tableData.splice(index, 1)
      this.selectKeys.splice(index, 1)
    },
    handlerDetail (id) {
      // this.$router.push({
      //   path: "/goods-edit",
      //   query: { type: "detail", id: id },
      // })
      const newPage = this.$router.resolve({
        path: "/goods-edit",
        query: { type: "detail", id: id },
      })
      window.open(newPage.href, "_blank")

    },
    handlerEditType () {
      this.isShowType = true
    },
    //行拖拽
    rowDrop () {
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
      const tbody = document.querySelector(
        "#mallListTable .ant-table .ant-table-body .ant-table-tbody"
      )
      const _this = this
      this.sort_table = Sortable.create(tbody, {
        group: ".ant-table-row",
        handle: ".ant-table-row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          const currKey = _this.selectKeys.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.selectKeys.splice(newIndex, 0, currKey)
        },
      })
    },
    stopRowDrop(){
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>